// Migrated
<template lang="pug">
.position-relative
  slot(
    :context="context",
    :actions="{ selectItemFromList, shiftResultsSelection, unshiftResultsSelection }",
    :events="{ inputHasReceivedFocus, inputHasChanged }"
    name="input"
  )
    input.vbga-input(
      ref="search"
      v-model="context.input"
      type="search"
      @focus="inputHasReceivedFocus"
      @input="inputHasChanged"
      @keydown.enter.prevent="selectItemFromList"
      @keydown.down.prevent="shiftResultsSelection"
      @keydown.up.prevent="unshiftResultsSelection"
    )

  ul.vbga-results(v-if="hasResults" ref="results")
    li(
      v-for="(result, index) in autocomplete.results",
      :key="result.id",
      :class="{ highlighted: index === autocomplete.resultsHighlight }"
      @click="resultHasBeenSelected(result)"
    )
      slot(
        v-if="index !== autocomplete.resultsHighlight",
        name="item"
        :place="result"
      ) {{ result.description }}

      slot(
        v-if="index === autocomplete.resultsHighlight",
        name="activeItem"
        :place="result"
      ) {{ result.description }}
</template>

<script>
import Fuse from 'fuse.js'

export default defineNuxtComponent({
  setup () {
    const config = useRuntimeConfig()
    const googleApiKey = config.public.GOOGLE_API_KEY

    return {
      googleApiKey,
    }
  },
  props: {
    bounds: {
      type: Object,
      required: false,
      default: null,
    },

    fields: {
      type: Array,
      required: false,
      default: () => ([]),
    },

    value: {
      type: String,
      required: false,
      default: '',
    },

    place: {
      type: Object,
      required: false,
      default: () => ({}),
    },

    country: {
      type: [Array, String],
      default: null,
    },

    options: {
      type: Object,
      default: () => ({}),
    },

    prepend: {
      type: Array,
      default: null,
    },
  },

  emits: ['resultChanged', 'selectDestination', 'resultCleared'],

  data () {
    return {
      autocomplete: {
        service: null,
        sessionToken: null,
        results: [],
        resultsHighlight: 0,
        status: null,
        selected: this.place,
      },

      context: {
        input: this.value,
        disableSearch: false,
      },

      timer: null,
      loaded: false,
      initAttempt: 0,
    }
  },

  computed: {
    hasResults () {
      return this.autocomplete.results.length > 0
    },

    searchValue () {
      return this.context.input
    },

    resultFields () {
      return [
        'formatted_address',
        'geometry',
        ...this.fields,
      ]
    },

    mapOptions () {
      const options = Object.assign({}, this.options)

      if (this.country) {
        Object.assign(options, {
          componentRestrictions: {
            country: this.country,
          },
        })
      }
      return options
    },
  },

  watch: {
    value: {
      handler (value) {
        if (!value) {
          return
        }
        this.context.input = value
      },

      immediate: true,
    },

    place: {
      handler (value) {
        if (!value) {
          return
        }
        this.autocomplete.selected = value
      },

      immediate: true,
    },

    searchValue (newValue, oldValue) {
      if (newValue || !oldValue) {
        return
      }

      this.$emit('resultCleared')
    },
  },

  beforeUnmount () {
    clearTimeout(this.timer)
  },

  created () {
    loadScript(`https://maps.googleapis.com/maps/api/js?key=${this.googleApiKey}&libraries=places`, 'google-maps', () => {
      this.loaded = true
    })
  },

  methods: {
    initGoogleAutoCompleteService () {
      try {
        this.autocomplete.sessionToken = new window.google.maps.places.AutocompleteSessionToken()
        this.autocomplete.service = new window.google.maps.places.AutocompleteService()
      } catch (e) {
        this.initAttempt++
        if (this.initAttempt < 15) {
          this.timer = window.setTimeout(this.initGoogleAutoCompleteService, this.loaded ? 250 : 500)
        }
      }
    },

    selectItemFromList () {
      const { results, resultsHighlight, selected } = this.autocomplete
      const { input } = this.context

      /**
       * Bail if there is nothing to work with
       */
      if (!input && !results.length) {
        return
      }

      /**
       * Return the last result if things haven't changed
       */
      if (input === this.value && Object.keys(selected).length) {
        return this.returnLastSelection()
      }

      /**
       * Show the search results again
       */
      if (input && !results.length) {
        return this.inputHasChanged()
      }

      /**
       * The expected standard user journey. The user selected a result from the list.
       */
      this.resultHasBeenSelected(results[resultsHighlight] || this.place)
    },

    shiftResultsSelection () {
      const { results, resultsHighlight } = this.autocomplete
      let newIndex = Math.min(results.length, resultsHighlight) + 1

      if (newIndex >= results.length) {
        newIndex = 0
      }

      this.autocomplete.resultsHighlight = newIndex
    },

    unshiftResultsSelection () {
      const { results, resultsHighlight } = this.autocomplete
      let newIndex = Math.min(results.length, resultsHighlight) - 1

      if (newIndex < 0) {
        newIndex = results.length - 1
      }

      this.autocomplete.resultsHighlight = newIndex
    },

    inputHasReceivedFocus () {
      if (this.autocomplete.service) {
        return
      }

      this.initGoogleAutoCompleteService()
    },

    inputHasChanged () {
      const { service, sessionToken } = this.autocomplete
      const { input } = this.context
      const { bounds } = this

      if (!service || !sessionToken) {
        // not initialized yet
        return false
      }

      this.autocomplete.resultsHighlight = 0
      if (!input) {
        this.autocomplete.selected = {}
        this.autocomplete.results = []
        return
      }

      service.getPlacePredictions({
        input,
        sessionToken,
        bounds,
        ...this.mapOptions,
      }, (predictions, status) => {
        this.autocomplete.status = status

        if (status !== window.google?.maps?.places?.PlacesServiceStatus.OK) {
          return
        }

        const options = {
          threshold: 0.2,
          keys: ['name', 'country'],
        }

        const fuse = new Fuse(this.prepend, options)
        const search = fuse.search(this.searchValue)
          .slice(0, 4)
          .map(i => ({
            ...i.item,
            description: `${i.item.name}, ${i.item.country}`,
          }))

        predictions = predictions
          .filter((p) => {
            return !search.find(i => p.structured_formatting.main_text.localeCompare(i.name) >= 0)
            // return !predictions.find(p => p.structured_formatting.main_text.match(i.item.name))
          })

        predictions.unshift(...(search))

        this.autocomplete.results = predictions
      })
    },

    resultHasBeenSelected (destination) {
      const placeId = destination.place_id
      const description = destination.description
      if (!placeId) {
        return this.$emit('selectDestination', destination)
      }

      const placeService = new window.google.maps.places.PlacesService(document.createElement('div'))

      placeService.getDetails({
        placeId,
        fields: this.resultFields,
      }, (place) => {
        this.autocomplete.selected = place
        this.context.input = description
        this.autocomplete.results = []
        this.$emit('resultChanged', place)
      })
    },

    returnLastSelection () {
      const { selected: place } = this.autocomplete

      if (!place) {
        return
      }

      this.$emit('resultChanged', place)
    },

  },
})
</script>
