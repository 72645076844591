// Migrated
<template lang="pug">
.col
  .charter-search
    PackageSearchAirportButton(
      ref="charterButtonAirport",
      aria-haspopup="true"
      aria-labelledby="charterButtonLabelAirport"
      :is-active="activeSearch === 'airport'"
      :select-destination-first="selectDestinationFirst"
      :selected-airport="selectedAirport"
      @click="setActiveSearch('airport')"
    )

    #charterButtonDestination.charter-search__btn(
      ref="charterButtonDestination",
      :class="{ active: activeSearch === 'destination' }"
      :aria-expanded="activeSearch === 'destination'"
      aria-haspopup="true"
      aria-labelledby="charterButtonLabelDestination"
      @click="setActiveSearch('destination')"
    )
      .charter-search__btn-title#charterButtonLabelDestination
        fa.mr-2(:icon="icons.faMapLocationDot")
        span {{ $t('charterSearchDestination') }}
      .charter-search__btn-text
        span(v-if="!selectedDestination") {{ $t('charterSearchSelect') }}
        span(v-else) {{ selectedDestination.name }}
        fa.charter-search__btn-icon(
          :icon="activeSearch === 'destination' ? 'caret-square-up' : 'caret-square-down'"
        )

      .charter-search__btn-title.text-error.blink.blink-title(v-if="selectAirportFirst") {{ $t('charterSelectAirportFirst') }}

    #charterButtonDate.charter-search__btn(
      ref="charterButtonDate",
      :class="{ active: activeSearch === 'date' }"
      :aria-expanded="activeSearch === 'date'"
      aria-haspopup="true"
      aria-labelledby="charterButtonLabelDate"
      @click="setActiveSearch('date')"
    )
      .charter-search__btn-title#charterButtonLabelDate
        fa.mr-2(:icon="icons.faCalendarAlt")
        span {{ $t('charterSearchDate') }}
      .charter-search__btn-text
        span(v-if="!displayDateRange") {{ $t('charterSearchSelect') }}
        span(v-else) {{ localeDate(displayDateRange.start) }}
          small.d-block(v-if="displayDateRange.end") {{ $t('charterSearchDateUntil', { n: localeDate(displayDateRange.end) }) }}
        fa.charter-search__btn-icon(
          :icon="activeSearch === 'date' ? 'caret-square-up' : 'caret-square-down'"
        )

      .charter-search__btn-title.text-error.blink.blink-title(v-if="selectDestinationFirst") {{ $t('charterSelectDestinationFirst') }}
      .charter-search__btn-title.text-error.blink.blink-title(v-if="selectReturnDate") {{ $t('charterLegendSelectFlightDates') }}

    CharterSearchButtonGuests(
      :active="activeSearch === 'guests'"
      :display-rooms="displayRooms"
      :select-child-ages="selectChildAges"
      @set-active="setActiveSearch('guests')"
    )
    .charter-search__btn-search
      button.rounded-box.btn-orange(
        type="button"
        @click="search"
      ) {{ $t('searchButtonText') }}

  #charter-parent.position-relative
    CharterAirportSelect(
      key="charterWindowAirportParent"
      ref="charterWindowAirportParent"
      :active="activeSearch === 'airport'"
      :selected-airport="selectedAirport"
      :prefilled-selected-airport="prefilledSelectedAirport"
      :prefill="prefill"
      @close="setActiveSearch(null)"
      @select="selectAirport"
    )
    transition-group(
      name="grow-down-menu"
      tag="div"
    )
      .charter-search__window(
        v-if="activeSearch === 'destination'"
        id="charterWindowDestination"
        ref="charterWindowDestination"
        key="charterWindowDestination"
      )
        .charter-search__window-content.overflow-visible
          .charter-search__header.d-flex.justify-content-between
            h5.mb-0 {{ $t('charterSelectDestination') }}
              small.ml-3.text-error.blink.blink-title.d-block.d-lg-none(v-if="selectDestinationFirst") {{ $t('charterSelectDestinationFirst') }}
            button.btn.py-0.d-block.d-lg-none.m-0.font-size-20(
              type="button"
              @click="setActiveSearch(null)"
            )
              fa(icon="times")

          .row.charter-search__list
            .col-md-4(
              v-for="(list, idx) in paginatedDestinations"
              :key="idx"
              )
              .charter-search__list-item(
                v-for="(destinationL1, index) in list",
                :key="`d${index}`",
                :class="{ active: destinationL1.name === selectedDestinationL1 }"
              )
                a.btn.d-flex.justify-content-between.w-100.px-0(
                  v-if="destinationL1.destinations.length > 1"
                  :class="{ 'font-weight-bold': destinationL1.name === selectedDestinationL1 }",
                  @click="selectDestinationL1(destinationL1.name)"
                ) {{ destinationL1.name }}
                  fa.charter-search__btn-icon(
                    :icon="destinationL1.name === selectedDestinationL1 ? 'chevron-up' : 'chevron-down'"
                  )
                a.btn.d-flex.justify-content-between.w-100.px-0(
                  v-else
                  :class="{ 'font-weight-bold': destinationL1.destinations[0] === selectedDestination }",
                  @click="selectDestination(destinationL1.destinations[0])"
                ) {{ destinationL1.name }}

                ul.pl-3(v-if="destinationL1.name === selectedDestinationL1")
                  li(
                    v-for="destination in destinationL1.destinations",
                    :key="destination.destination_id"
                  )
                    button(
                      type="button"
                      :class="{ active: destination === selectedDestination }",
                      @click="selectDestination(destination)"
                    ) {{ destination.name }}

            .col-md-12(v-if="destinationCount < 20")
              .alert.alert-info.text-black.mt-3
                fa(icon="exclamation-circle")
                span.ml-2 {{ $t('charterLowDestinationWarning') }}

            .col-12.col-md-6.col-lg-4.border-0.mt-3
              label.font-weight-bold(for="charterDestinationSearch") {{ $t('charterGoAnywhere') }}
              GooglePlacesAutocomplete(
                :options="{ types: ['(cities)'] }"
                :value="customDestinationName"
                :prepend="prependDestinations"
                @select-destination="selectDestination",
                @result-changed="selectCustomDestination",
                @result-cleared="selectCustomDestination(null)"
              )
                template(#input="{ context, events, actions }")
                  .charter-search__places(
                    :class="{ active: !!customDestinationName }"
                  )
                    input#charterDestinationSearch.form-control(
                      ref="search"
                      v-model="context.input"
                      type="search"
                      :placeholder="$t('charterGoAnywherePlaceholder')"
                      autocomplete="off"
                      @focus="events.inputHasReceivedFocus"
                      @input="events.inputHasChanged"
                      @keydown.enter.prevent="actions.selectItemFromList",
                      @keydown.down.prevent="actions.shiftResultsSelection"
                      @keydown.up.prevent="actions.unshiftResultsSelection"
                    )

                template(#item="{ place }")
                  span.cursor-pointer.hover-bg-light-gray.d-block.p-2 {{ place.description }}

                template(#activeItem="{ place }")
                  span.cursor-pointer.hover-bg-light-gray.d-block.p-2.orange-bg {{ place.description }}

      .charter-search__window(
        v-if="activeSearch === 'date'"
        id="charterWindowDate"
        ref="charterWindowDate"
        key="charterWindowDate"
      )
        .charter-search__window-content
          h5.charter-search__header {{ $t('charterSelectDate') }}
            button.btn.py-0.d-block.d-lg-none.m-0.font-size-20(
              type="button"
              @click="setActiveSearch(null)"
            )
              fa(icon="times")

          client-only
            ScreenWidthProvider.d-flex.justify-content-center.mb-5.mt-4(v-slot="{ isNarrower, isWider }")
              DatePicker(
                ref="charterFC",
                v-model:range="selectedStartDate"
                v-bind="calendarConfig",
                :rows="isNarrower('md') ? 12 : isWider('md') && isNarrower('lg') ? 6 : 1"
                @dayclick="calendarChoseDay"
              )
                template(#day-content="{ day, dayProps, dayEvents }")
                  button.bg-transparent.p-0.h-100.w-100.d-flex.align-items-center.justify-content-center.position-relative(
                    v-bind="dayProps"
                    :class="{ 'opacity-50': day.isDisabled }"
                    :disabled="day.isDisabled"
                    v-on="dayEvents"
                  )
                    | {{ day.day }}

                    small.position-absolute.right-0.top-0.direct-flight-day(v-if="directFlightDay(day)")
                      fa(:icon="icons.faPlane")

            .border-box.mt-3(v-if="window > 768 && (directFlightDays.length || (selectedStartDate.dateRange.start && !selectedStartDate.dateRange.end))")
              transition(
                name="fade"
                mode="out-in")
                .btn-orange-transparent.p-1.legend-text.blink(
                  v-if="selectedStartDate.dateRange.start && !selectedStartDate.dateRange.end"
                ) {{ $t('charterLegendSelectReturnFlight') }}

              div(v-if="directFlightDays.length")
                fa(:icon="icons.faPlane")
                span.ml-2 {{ $t('charterLegendDirectFlight') }}
            .charter-search__fixed-bottom(v-else-if="window <= 768")
              p.btn-orange-transparent.p-1.blink(
                v-if="selectedStartDate.dateRange.start && !selectedStartDate.dateRange.end"
              ) {{ $t('charterLegendSelectReturnFlight') }}
              .border-box.mt-3(v-if="directFlightDays.length")
                fa(:icon="icons.faPlane")
                span.ml-2 {{ $t('charterLegendDirectFlight') }}

    LazyCharterSearchWindowGuests(
      v-if="activeSearch === 'guests'"
      key="charterWindowGuestsParent"
      :rooms="rooms"
      :passengers-full="passengersFull"
      @clear="setActiveSearch(null)"
      @update="updateRooms"
      @search="search"
    )
  LazyCharterBookingErrorModal(
    :errors="tooManyInfants"
    :no-close-on-esc="false"
    :close-self="true"
  )
    template(#text)
      span {{ $t('charterSearchTooManyInfants') }}
</template>

<script>
import { faPlane, faPlaneDeparture, faMapLocationDot, faCalendarAlt, faUserFriends } from '@fortawesome/pro-duotone-svg-icons'
import { mapActions, mapState } from 'pinia'
import { DATEPICKER_DATE_FORMAT } from '@packages/date/formats'

export default defineNuxtComponent({
  setup () {
    const { localeDate } = useDate()

    return {
      localeDate,
    }
  },

  props: {
    autofocus: {
      type: Boolean,
      default: true,
    },

    header: {
      type: Boolean,
      default: false,
    },

    prefill: {
      type: Object,
      default: null,
    },

    prefilledSelectedAirport: {
      type: Object,
      default: null,
    },

    doSearch: {
      type: Boolean,
      default: true,
    },

    maxCalendars: {
      type: Number,
      default: 3,
    },
  },

  emits: ['selectAirport', 'search'],

  data () {
    return {
      icons: {
        faPlane,
        faPlaneDeparture,
        faMapLocationDot,
        faCalendarAlt,
        faUserFriends,
      },

      query: this.$route.query.q,

      selectedAirport: null,
      selectedDestination: null,
      selectedDestinationL1: null,
      ageArray: [...Array(18).keys()],
      selectedStartDate: {
        'currentDate': null,
        'selectedDate': false,
        'selectedDateTime': false,
        'selectedHour': '00',
        'selectedMinute': '00',
        'selectedDatesItem': '',
        'selectedDates': [],
        'dateRange': {
          'start': '',
          'end': '',
        },

        'multipleDateRange': [],
      },

      activeSearch: null,
      selectDestinationFirst: false,
      selectAirportFirst: false,
      selectReturnDate: false,
      selectChildAges: false,
      tooManyInfants: false,

      window: 0,
      timer: null,
    }
  },

  computed: {
    ...mapState(useRootStore, {
      airports: 'charterAirports',
      destinations: 'charterDestinations',
    }),

    ...mapState(useCharterStore, {
      calendarSuggestions: 'calendarSuggestions',
    }),

    ...mapState(useCharterPackagesStore, {
      rooms: 'rooms',
      getNoOfPassengers: 'getNoOfPassengers',
      overMaxNoOfPassengers: 'overMaxNoOfPassengers',
      roomsHaveEmptyAgeValues: 'roomsHaveEmptyAgeValues',
      peoplePerRoom: 'peoplePerRoom',
    }),

    filteredAirports () {
      return this.airports.filter(a => a.airport !== 'ZZZ')
    },

    normalAirports () {
      return this.filteredAirports.filter(a => !a.featured_airport)
    },

    featuredAirports () {
      return this.filteredAirports.filter(a => !!a.featured_airport)
    },

    filteredDestinations () {
      const ap = this.selectedAirport?.airport

      return this.destinations.map(d1 => ({
        ...d1,
        destinations: d1.destinations.filter(d => !!d.avalible_from_airports?.includes(ap)),
      })).filter(d => d.destinations.length > 0)
    },

    prependDestinations () {
      return this.filteredDestinations.flatMap(d => d.destinations.map(dd => ({
        ...dd,
        country: d.name,
      })))
    },

    paginatedDestinations () {
      return [
        this.filteredDestinations.slice(0, Math.ceil(this.filteredDestinations.length / 3)),
        this.filteredDestinations.slice(Math.ceil(this.filteredDestinations.length / 3), Math.ceil(this.filteredDestinations.length / 3) * 2),
        this.filteredDestinations.slice(Math.ceil(this.filteredDestinations.length / 3) * 2, Math.ceil(this.filteredDestinations.length)),
      ]
    },

    destinationCount () {
      return this.filteredDestinations.reduce((acc, curr) => {
        return acc + curr.destinations.length
      }, 0)
    },

    elemId () {
      return this.header ? 'articles-search-header' : 'articles-search'
    },

    calendarsCount () {
      return this.window > 1599 ? this.maxCalendars :
        this.window > 920 ? 2 :
          this.window <= 768 ? 12 : 1
    },

    calendarConfig () {
      return {
        color: 'solresor',
        borderless: true,
        showWeeknumbers: true,
      }
    },

    displayDateRange () {
      if (this.selectedStartDate.dateRange && this.selectedStartDate.dateRange.start) {
        return this.selectedStartDate.dateRange
      }

      return null
    },

    displayRooms () {
      return this.peoplePerRoom
    },

    /**
     * Max number of passengers is 9, regardless of age
    */
    passengersFull () {
      return this.overMaxNoOfPassengers
    },

    customDestinationName () {
      return this.selectedDestination && this.selectedDestination.custom ? this.selectedDestination.formatted_address : null
    },

    directFlightDays () {
      if (!this.selectedStartDate.dateRange.start) {
        return this.calendarSuggestions.outbound
      }
      return this.calendarSuggestions.homebound
    },

    forcedCalendarDays () {
      if (!this.calendarSuggestions.forced) {
        return []
      }

      if (!this.selectedStartDate.dateRange.start) {
        return Object.keys(this.calendarSuggestions.outbound).map(d => this.$dayjs(d).format(DATEPICKER_DATE_FORMAT))
      }
      if (!this.selectedStartDate.dateRange.end) {
        return Object.keys(this.calendarSuggestions.homebound).map(d => this.$dayjs(d).format(DATEPICKER_DATE_FORMAT))
      }

      return Object.keys(this.calendarSuggestions.outbound).map(d => this.$dayjs(d).format(DATEPICKER_DATE_FORMAT))
    },
  },

  watch: {
    prefilledSelectedAirport (v) {
      if (!v) {
        return
      }
      this.selectedAirport = v
    },
  },

  mounted () {
    window.addEventListener('resize', this.resizeListener)

    this.window = window.innerWidth

    if (this.prefill) {
      this.selectedDestination = this.prefill.selectedDestination
      this.selectedDestinationL1 = this.prefill.selectedDestinationL1
      this.selectedStartDate = this.prefill?.selectedStartDate || this.selectedStartDate
      if (this.prefill?.rooms) {
        this.setRooms(this.prefill.rooms)
      }
    }
  },

  beforeUnmount () {
    clearTimeout(this.timer)
    window.removeEventListener('resize', this.resizeListener)
  },

  methods: {
    ...mapActions(useCharterPackagesStore, {
      setSelectedRooms: 'SET_SELECTED_ROOMS',
      setRooms: 'SET_ROOMS',
    }),

    ...mapActions(useCharterStore, {
      setCalendarSuggestions: 'SET_CALENDAR_SUGGESTIONS',
      getCalendarSuggestions: 'getCalendarSuggestions',
      charterSearch: 'search',
    }),

    resizeListener () {
      this.window = window.innerWidth
    },

    search () {
      if (!this.selectedAirport) {
        return this.setActiveSearch('airport')
      }
      if (!this.selectedDestination) {
        return this.setActiveSearch('destination')
      }
      if (!this.selectedStartDate.dateRange.start || !this.selectedStartDate.dateRange.end) {
        if (!this.selectedStartDate.dateRange.end) {
          this.selectReturnDate = false
          this.$nextTick(() => {
            this.selectReturnDate = true
          })
        }
        return this.setActiveSearch('date')
      }
      if (this.roomsHaveEmptyAgeValues) {
        this.selectChildAges = false
        this.$nextTick(() => {
          this.selectChildAges = true
          this.setActiveSearch('guests')
        })
        return
      }

      if (this.peoplePerRoom.infants > this.peoplePerRoom.adults) {
        this.tooManyInfants = true
        return
      }

      const params = {
        selectedAirport: this.selectedAirport,
        selectedDestination: this.selectedDestination,
        selectedDestinationL1: this.selectedDestinationL1,
        rooms: this.rooms,
        selectedStartDate: this.selectedStartDate,
      }
      this.$emit('search', params)

      // Prevent double calling search with redirects to search page that calls search onmount
      if (this.doSearch) {
        this.charterSearch(params)
      }

      this.setActiveSearch(null)
    },

    setActiveSearch (search) {
      this.activeSearch = this.activeSearch === search ? null : search

      if (this.activeSearch && this.window <= 768) {
        window.document.body.classList.add('sideMenuActive')
        window.document.documentElement.classList.add('sideMenuActive')
      } else {
        window.document.body.classList.remove('sideMenuActive')
        window.document.documentElement.classList.remove('sideMenuActive')
      }

      this.selectDestinationFirst = false
      this.selectAirportFirst = false

      if ((this.activeSearch === 'date' || this.activeSearch === 'destination') && !this.selectedAirport) {
        this.activeSearch = 'airport'
        this.selectAirportFirst = true
      } else if (this.activeSearch === 'date' && !this.selectedDestination) {
        this.activeSearch = 'destination'
        this.selectDestinationFirst = true
      } else if (search === 'guests' && this.selectChildAges) {
        this.activeSearch = 'guests'
      }

      this.$nextTick(() => {
        let pop = null
        switch (this.activeSearch) {
          case 'airport':
            pop = this.$refs.charterWindowAirport
            break
          case 'destination':
            pop = this.$refs.charterWindowDestination
            break
          case 'date':
            pop = this.$refs.charterWindowDate
            break
          case 'guests':
            pop = this.$refs.charterWindowGuests
            break
        }

        if (!pop) {
          return
        }

        this.timer = window.setTimeout(() => {
          this.$nextTick(() => {
            const rect = pop.getBoundingClientRect?.()

            const offset = 0 // 50

            // document.documentElement.clientHeight instead of window.innerHeight because of iOS
            if (rect.top + pop.scrollHeight + offset < window.pageYOffset + document.documentElement.clientHeight) {
              return
            }

            try {
              window.scrollTo({
                top: rect.top + pop.scrollHeight + offset - document.documentElement.clientHeight,
                behavior: 'smooth',
              })
            } catch {
              window.scrollTo(0, rect.top + pop.scrollHeight + offset - document.documentElement.clientHeight)
            }
          })
        }, 50)
      })
    },

    selectAirport ([airport, fullUpdate]) {
      this.$emit('selectAirport', airport)
      this.selectedAirport = airport

      if (fullUpdate) {
        this.loadSuggestions()
        this.timer = window.setTimeout(() => {
          this.setActiveSearch('destination')
        }, 200)
      }
    },

    selectDestinationL1 (index) {
      this.selectedDestinationL1 = this.selectedDestinationL1 === index ? null : index
    },

    selectDestination (destination) {
      this.selectedDestination = destination
      this.loadSuggestions()
      this.timer = window.setTimeout(() => {
        this.setActiveSearch('date')
      }, 200)
    },

    selectCustomDestination (destination) {
      if (!destination || !destination.geometry) {
        this.selectedDestination = null
        return
      }

      this.selectedDestination = {
        ...destination,
        name: destination.formatted_address,
        custom: true,
        latitude: destination.geometry.location.lat(),
        longitude: destination.geometry.location.lng(),
      }

      this.timer = window.setTimeout(() => {
        this.setActiveSearch('date')
      }, 200)

      this.loadSuggestions()
    },

    updateRooms ({ rooms, emptyAgeValues }) {
      this.setRooms(rooms)
      this.selectChildAges = emptyAgeValues
    },

    calendarChoseDay (day, switchToGuests = true) {
      if (day.date === this.selectedStartDate.dateRange.start && this.selectedStartDate.dateRange.end) {
        // Refuse click on same day
        this.selectedStartDate.dateRange.end = ''
        this.selectReturnDate = true
        return
      }

      if (this.selectedStartDate.dateRange.end) {
        this.selectReturnDate = false
      }

      if (switchToGuests && this.selectedStartDate.dateRange.end) {
        this.timer = window.setTimeout(() => {
          this.setActiveSearch('guests')
        }, 200)
      }
    },

    directFlightDay (day) {
      if (this.directFlightDays[this.localeDate(day.date)]) {
        return true
      }

      return false
    },

    async loadSuggestions () {
      this.setCalendarSuggestions({
        forced: false,
        homebound: {},
        outbound: {},
      })

      if (!this.selectedDestination || !this.selectedAirport) {
        return
      }

      const destination = this.selectedDestination.custom ? {
        latitude: this.selectedDestination.latitude,
        longitude: this.selectedDestination.longitude,
      } : { destination_id: this.selectedDestination.destination_id }

      try {
        await this.getCalendarSuggestions({
          airport: this.selectedAirport.airport,
          ...destination,
        })
      } catch (e) {
        this.$sentrySetContext('data', JSON.stringify({ error: e?.data || e }))
        this.$sentryCaptureException('charter/getCalendarSuggestions error', {
          level: 'warning',
          tags: {
            type: 'UX',
          },
        })
      }
    },
  },
})
</script>

<style lang="scss">
.blink {
  animation: blink 1.22s;
  background: $orange-transparent;

  &-title {
    background: transparent;
  }
}

@keyframes blink {
  0% {
    background: transparent;
  }

  20%,
  80% {
    background: $orange;
  }

  50% {
    background: $orange-transparent;
  }
}
</style>
